<template>
<Wrapper ref="wrapper">
    <div class="serviceCenter">
        <DetailBanner titleType='2' title=""></DetailBanner>
        <water-title Chinese="服务大厅"></water-title>
        <div class="content">
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal">
                <el-menu-item index="1">{{name}}</el-menu-item>
            </el-menu>
            <el-row :gutter="20">
                <el-col :span="6" v-for="(item,index) in list" :key="index">
                    <div @click="serviceDetail(item,index)" class="imgDiv">
                        <el-image :src="imgBaseUrl+item.pic">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </div>
                    <div @click="serviceDetail(item,index)" class="info">
                        <h6>{{item.name}}</h6>
                        <span>
                            <i class="el-icon-location-outline" />{{item.addr}}</span>
                        <span>{{item.addDetail}}</span>
                    </div>
                </el-col>
            </el-row>
        </div>
  </div>
</Wrapper>
</template>
<script>
import DetailBanner from "@/views/sections/DetailBanner.vue";
import Wrapper from '@/components/Wrapper.vue'
import WaterTitle from "@/components/WaterTitle.vue";
import { INFO_URL } from '@/config/globalConfig'
export default {
  components: { Wrapper, DetailBanner, WaterTitle },
  data() {
    return {
        imgBaseUrl:INFO_URL,
      activeIndex: "1",
      name: '',
      list: []
    };
  },
  created(){
      let curService = localStorage.getItem('curService')
      if(curService){
          curService = JSON.parse(curService)
          this.name = curService.name
          this.list = curService.companyList
      }
  },
  mounted(){
     window.scrollTo(0,0);
  },
  methods: {
      serviceDetail(item, index){
          this.$router.push({
              path: `/serviceHallDetail?id=${item.id}`
          })
      }
  }
};
</script>
<style lang="scss" scoped>
.serviceCenter{
    margin-top: 50px;
}
.content{
    width: 1200px;
    margin: 60px auto 140px;
    .el-menu{
        .el-menu-item{
            font-size: 22px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: #181F2D;
        }
    }
    .el-row{
        margin-top: 20px;
        .el-col{
            cursor: pointer;
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .imgDiv{
                text-align: center;
                padding: 15px 0;
                background-color: #F3F5F8;
                border-radius: 20px 20px 0 0;
                width: 100%;
                .el-image{
                    border-radius: 50%;
                    width: 152px;
                    height: 152px;
                }
            }   
            .info{
                width: 100%;
                border-radius: 0 0 20px 20px;
                background-color: #315CCF;
                display: flex;
                flex-direction: column;
                color: #fff;
                padding: 30px;
                line-height: 30px;
                text-align: center;
                h6{
                    font-weight: bold;
                }
            }
        }
    }
}
</style>
